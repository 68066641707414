import React, { Component } from "react";
import AlertMessageBootstrap from "../../geral/AlertMessageBootstrap";

const axios = require("axios");
require("dotenv").config();

class UploadImageRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AlertMessage: "",
      hideRow: "",
      fileName: this.props.data.fileName,
      imgOrder: this.props.data.imgOrder,
      imgLabel: this.props.data.label,
      alternativeText: this.props.data.alternativeText,
      labelSave: "",
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
  }

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }

  handleChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    this.setState({ [id]: value });
  };

  handleChangeLabel = (e) => {
    const value = e.target.value;
    // console.log(value);
    // console.log(this.props.data.id);
    this.setState({
      labelSave: <span className="d-block">Saving...</span>,
      imgLabel: value,
    });
    var self = this;
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}boat-upload-row/${self.props.data.id}`,
      data: {
        label: value,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        // console.log(response);
        this.setState({
          labelSave: <span className="d-block text-success">Label Saved!</span>,
        });
        setTimeout(() => {
          this.setState({ labelSave: "" });
        }, 2000);
      })
      .catch((response) => {
        // console.log(response);
        this.setState({
          labelSave: <span className="d-block text-danger">Error!</span>,
        });
        setTimeout(() => {
          this.setState({ labelSave: "" });
        }, 2000);
      });
  };

  submitForm = () => {
    var self = this;
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}boat-upload-row/${self.props.data.id}`,
      data: {
        imgOrder: this.state.imgOrder,
        alternativeText: this.state.alternativeText,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }
        self.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Erro!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  deleteImage = () => {
    var self = this;
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}boat-upload-row/${self.props.data.id}`,
    })
      .then((response) => {
        const { msg, success } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }
        self.setState({
          hideRow: "d-none",
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage:(
            <AlertMessageBootstrap
              messageType="danger"
              message="Erro!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  render() {
    const imgLabelBlock  = this.props.imgLabel;
    let { imgLabel } = this.state;
    return (
      <tr className={this.state.hideRow}>
        <th scope="row">
          <img
            src={`${process.env.REACT_APP_PUBLIC_FOLDER}/images/${this.props.data.boatId}/${this.props.data.thumbName}`}
            alt=""
            width="150px"
          />
        </th>
        <td>
          <input
            type="number"
            id="imgOrder"
            maxLength="3"
            required=""
            value={this.state.imgOrder}
            className="w-75"
            onChange={this.handleChange}
          />
        </td>
        <td>
          {!imgLabelBlock && 
          <input
            type="text"
            id="alternativeText"
            maxLength="255"
            required=""
            value={this.state.alternativeText}
            className="w-90"
            onChange={this.handleChange}
          />
          }
          {localStorage.userDealerId === "5" && !imgLabelBlock ? (
            <div className="w-100">
              <p className="w-100 my-1 badge badge-secondary">
                Presentation Label
              </p>
              <select value={imgLabel} onChange={this.handleChangeLabel}>
                <option value=""></option>
                <option value="Hero">Hero</option>
                <option value="Interior">Interior</option>
                <option value="Exterior">Exterior</option>
                <option value="Cabin">Cabin</option>
                <option value="Key Vessel">Key Vessel</option>
                <option value="Engine">Engine</option>
                <option value="Gallery">Gallery</option>
                 <option value="Website-Interior">Website Interior</option>
                <option value="Website-Exterior">Website Exterior</option>
                <option value="Website-Cabin">Website Cabin</option>
              </select>
              {this.state.labelSave}
            </div>
          ) : (
            ""
          )}

          {localStorage.userDealerId === "11" ? (
            <div className="w-100">
              <p className="w-50 d-block my-1 badge badge-secondary">
                Presentation Label
              </p>
              <select value={imgLabel} onChange={this.handleChangeLabel}>
                <option value=""></option>
                <option value="PhotoCardHero">Photo Card Hero</option>
                <option value="PhotoBrochureHeader">Brochure - Header</option>
                <option value="PhotoBrochureHero">Brochure - Hero</option>
                <option value="PhotoBrochureLeftsite">
                  Brochure - Left Side 1st Page
                </option>
                <option value="PhotoBrochureMain">
                  Brochure - Main 2nd Page
                </option>
                <option value="PhotoBrochureFeature1">
                  Brochure - Features 1
                </option>
                <option value="PhotoBrochureFeature2">
                  Brochure - Features 2
                </option>
                <option value="PhotoBrochureFeature3">
                  Brochure - Features 3
                </option>
              </select>
              {this.state.labelSave}
            </div>
          ) : (
            ""
          )}
        </td>
        <td>{this.state.fileName}</td>
        <td>
          <button
            type="button"
            className="btn btn-success ml-3 mb-2 mt-1"
            onClick={this.submitForm}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-danger ml-3 mb-2 mt-1"
            onClick={this.deleteImage}
          >
            Delete
          </button>
        </td>
        {this.state.AlertMessage}
      </tr>
    );
  }
}

export default UploadImageRow;
