import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

// App Components
import Login from "./components/Login";
import MainControl from "./MainControl";

//import 'bootstrap/dist/css/bootstrap.min.css';

//google server - https://noble-aquifer-219505.appspot.com/api/

class App extends React.Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/login" />} />
            <Route
              exact
              path="/login"
              component={() => (
                <Login
                  performUsers={this.performUsers}
                  returnUser={this.performUsers}
                />
              )}
            />
            <Route component={() => <MainControl />} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
