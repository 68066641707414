import React, { Component } from "react";
import UploadImageRow from "./UploadImageRow";
import AlertMessageBootstrap from "../../geral/AlertMessageBootstrap";

const axios = require("axios");
require("dotenv").config();

class UploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertMessage: null,
      selectedFiles: "",
      uploadButton: "",
      filesToUpload: [],
      rowImages: "",
      fileProgress: {},
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
  }

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }

  componentDidMount() {
    this.loadButton();
    this.handleRowImages();
  }

  loadButton() {
    this.setState({
      uploadButton: (
        <button
          type="button"
          className="btn btn-primary mb-2 mt-1"
          formAction="submit"
          onClick={this.handleUploadImages}
        >
          Upload Images
        </button>
      ),
    });
  }

  handleSubmitImages = (event) => {
    const files = Array.from(event.target.files);
    const validTypes = ["image/jpeg", "image/png"];

    const validFiles = files.filter(
      (file) =>
        validTypes.includes(file.type) &&
        !this.state.filesToUpload.some((f) => f.name === file.name)
    );

    this.setState((prevState) => ({
      selectedFiles: [...prevState.selectedFiles, ...validFiles],
      filesToUpload: [
        ...prevState.filesToUpload,
        ...validFiles.map((file) => ({
          name: file.name,
          size: file.size,
          type: file.type,
        })),
      ],
    }));

    if (files.length !== validFiles.length) {
      this.setState({
        alertMessage: (
          <AlertMessageBootstrap
            messageType="warning"
            message="Some files are not supported or already selected. Only JPEG, and PNG are allowed."
            unmountMe={this.handleCloseAlert}
          />
        ),
      });
    } else {
      this.setState({ alertMessage: null });
    }
  };

  removeFile = (index) => {
    const fileNameToRemove = this.state.filesToUpload[index].name;

    this.setState((prevState) => {
      const updatedFilesToUpload = prevState.filesToUpload.filter(
        (_, idx) => idx !== index
      );
      const updatedSelectedFiles = prevState.selectedFiles.filter(
        (file) => file.name !== fileNameToRemove
      );

      return {
        filesToUpload: updatedFilesToUpload,
        selectedFiles: updatedSelectedFiles,
        alertMessage:
          updatedFilesToUpload.length === 0
            ? (
                <AlertMessageBootstrap
                  messageType="info"
                  message="All files have been removed from the upload list."
                  unmountMe={this.handleCloseAlert}
                />
              )
            : null,
      };
    });
  };


  handleUploadImages = () => {
    const { selectedFiles } = this.state;

    if (!selectedFiles || selectedFiles.length === 0) {
      this.setState({
        alertMessage: (
          <AlertMessageBootstrap
            messageType="danger"
            message="No valid files selected!"
            unmountMe={this.handleCloseAlert}
          />
        ),
      });
      return;
    }

    const fileProgress = {};
    selectedFiles.forEach((file) => {
      fileProgress[file.name] = 0; 
    });
    this.setState({ fileProgress });

    const uploadPromises = selectedFiles.map((file) => {
      const data = new FormData();
      data.append("file", file);
      const imgLabel =
        typeof this.props.imgLabel === "string"
          ? this.props.imgLabel
          : JSON.stringify(this.props.imgLabel);

      data.append("imgLabel", imgLabel);

      return axios
        .post(
          `${process.env.REACT_APP_API_URL}boat-upload/${this.props.idBoat}/${localStorage.userId}`,
          data,
          {
            onUploadProgress: (progressEvent) => {
              const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              this.setState((prevState) => ({
                fileProgress: {
                  ...prevState.fileProgress,
                  [file.name]: progress,
                },
              }));
            },
          }
        )
        .then(() => ({
          name: file.name,
          status: "success",
        }))
        .catch(() => ({
          name: file.name,
          status: "error",
        }));
    });

    Promise.all(uploadPromises).then((results) => {
      const failedUploads = results.filter((result) => result.status === "error");
      const successUploads = results.filter((result) => result.status === "success");

      if (failedUploads.length) {
        this.setState({
          alertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message={`Failed to upload: ${failedUploads.map((f) => f.name).join(", ")}`}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      }

      if (successUploads.length) {
        this.setState({
          alertMessage: (
            <AlertMessageBootstrap
              messageType="success"
              message={`Successfully uploaded: ${successUploads.map((s) => s.name).join(", ")}`}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      }

      // Remove successfully uploaded files from the upload list
      const remainingFiles = selectedFiles.filter((file) =>
        failedUploads.some((failed) => failed.name === file.name)
      );
      this.setState({
        selectedFiles: remainingFiles,
        filesToUpload: remainingFiles.map((file) => ({
          name: file.name,
          size: file.size,
          type: file.type,
        })),
      });

      // Reload the images
      this.handleRowImages();
    });
  };


  handleRowImages = () => {
    var imgRows = [];
    var self = this;

    const imgLabel =
      typeof this.props.imgLabel === "string"
        ? this.props.imgLabel
        : JSON.stringify(this.props.imgLabel);
    
    self.setState({
      rowImages: <p className="m-3 text-info">Loading images...</p>,
    });

    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}boat-upload/${self.props.idBoat}/imgOrder`,
      params: { label: imgLabel },
    })
      .then(function (response) {
        console.log(
          `${process.env.REACT_APP_API_URL}boat-upload/${self.props.idBoat}`
        );

        if (response.data.success === false) {
          // No images found
          self.setState({
            rowImages: <p className="m-3 text-danger">No images found!</p>,
          });
          return false;
        }

        // Populate imgRows with image data
        for (let i = 0; i < response.data.data.results.length; i++) {
          const imageData = response.data.data.results[i];
          imgRows.push(
            <UploadImageRow
              idBoat={self.props.idBoat}
              data={imageData}
              key={imageData.id}
              imgLabel={imgLabel}
              progress={self.state.fileProgress[imageData.fileName] || 0}
            />
          );
        }

        // Update state with the loaded rows
        self.setState({
          rowImages: imgRows.length ? imgRows : <p className="m-3 text-danger">No images found!</p>,
        });

        // Re-load button
        self.loadButton();
      })
      .catch(function (error) {
        console.error("Error fetching image data:", error);
        self.setState({
          rowImages: <p className="m-3 text-danger">Error loading images!</p>,
        });
      });
  };

  render() {
    const imgLabel =
        typeof this.props.imgLabel === "string"
          ? this.props.imgLabel
          : JSON.stringify(this.props.imgLabel);
    return (
      <div style={{ width: "98%" }}>
        <div className="col-12 border border-info uploadFileRow">
          <input
            type="file"
            name="img"
            multiple
            className="uploadFileField"
            onChange={this.handleSubmitImages}
          />
          <hr className="mt-0 mb-0" />
          {this.state.uploadButton}
        </div>

        <div className="mt-3">
          <h5>Files Ready to Upload</h5>
          <ul className="list-group">
            {this.state.filesToUpload.map((file, index) => (
              <li key={file.name} className="list-group-item">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <strong>{file.name}</strong> ({(file.size / 1024).toFixed(2)} KB)
                  </div>
                  {/* Only show progress bar if upload has started */}
                  {this.state.fileProgress[file.name] !== undefined && (
                    <div className="progress" style={{ width: "50%" }}>
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: `${this.state.fileProgress[file.name] || 0}%`,
                        }}
                        aria-valuenow={this.state.fileProgress[file.name] || 0}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {this.state.fileProgress[file.name] || 0}%
                      </div>
                    </div>
                  )}
                  <button
                    type="button"
                    className="btn btn-sm btn-danger ml-3"
                    onClick={() => this.removeFile(index)}
                  >
                    Cancel
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>


        <h4 className="mt-3">
          Uploaded Images{" "}
          <span
            className="tx-14"
            style={{ cursor: "pointer" }}
            onClick={this.handleRowImages}
          >
            (<i className="fa fa-refresh"></i> Reload Images)
          </span>
        </h4>

        <div className="border border-info uploadFileRow ">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Image</th>
                <th scope="col">Order</th>
                {!imgLabel && <th scope="col">Alternate Text</th>}
                <th scope="col">File Name</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>{this.state.rowImages}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default UploadForm;
